<template>
    <img v-auth-src="mediaBasePath + mediaItemData.filePath" :alt="mediaItemData.name | cleanString" />
</template>

<script>
    import {MEDIA_ROOT} from "@/shared/consts";
    import {mapActions, mapState} from "vuex";

    import {formatContentMixin} from "@/views/mixins/formatContent";
    import {mediaGroupActionsMixin} from "@/views/mixins/mediaGroupActions";

    export default {
        name: 'MediaGroupMediaViewOriginal',
        mixins: [formatContentMixin, mediaGroupActionsMixin],
        data() {
            return {
                mediaItemData: [],
            }
        },
        computed: {
            ...mapState(['pageTitle']), // imports from store
            mediaGroupID() {
                return this.$route.params.mediaGroupID;
            },
            mediaItemID() {
                return this.$route.params.mediaItemID;
            },
            mediaBasePath() {
                return MEDIA_ROOT;
            }
        },
        methods: {
            ...mapActions(["setPageTitleAction", "setBreadCrumbsAction"]),
            async refreshMediaItem(){
                await this.loadMediaGroupMediaItemByID(this.mediaItemID);

                this.updatePageTitle();
            },
        },
        async mounted() {
            this.refreshMediaItem();
        },
    }
</script>