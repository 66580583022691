<template>
    <div :class="{ archive : mediaGroupData.archived}">
        <ul class="image-list" v-if="mediaGroupArchiveMediaData.length > 0">
            <li class="image-list-item" v-for="row in mediaGroupArchiveMediaData" :key="row.id">
                <span class="image-list-item-inner">
                    <router-link :to="{ name: 'AdminMediaGroupArchiveMediaView', params: { 'mediaItemID' : row.id } }" :title="text.view + ' ' + row.name" class="image image-border"><img v-auth-src="mediaBasePath + row.thumbnailFilePath" :alt="row.name | cleanString" /></router-link>
                    <p class="image-title">{{row.name}}</p>
                    <span class="image-meta">
                        <ViewAction :routeName="'AdminMediaGroupArchiveMediaView'" :routeParams="{ 'mediaItemID' : row.id }" :linkName="row.name" />
                        <span class="form-input" v-if="!mediaGroupData.archived">
                            <label :for="'selectimage' + row.id" class="form-checkbox">
                                <input type="checkbox" name="selectimage" :id="'selectimage' + row.id" :value="row.id" v-model="selectedMediaItems" /><span></span>
                            </label>
                        </span>
                    </span>
                </span>
            </li>
        </ul>
        <p v-else>{{text.no_results}}</p>
        <Pagination v-if="mediaGroupArchiveMediaPagination.totalPages > 0" :pagination="mediaGroupArchiveMediaPagination" @go-previous="refreshMediaItemList" @go-next="refreshMediaItemList" />
    </div>
</template>

<script>
    import lang from "@/shared/lang";
    import {MEDIA_ROOT} from "@/shared/consts";
    import {mapActions, mapState} from "vuex";

    import {formatContentMixin} from "@/views/mixins/formatContent";
    import {mediaGroupActionsMixin} from "@/views/mixins/mediaGroupActions";

    import Pagination from "@/components/pagination";
    import ViewAction from "@/components/actionlinks/view";

    export default {
        name: 'MediaGroupArchiveMediaList',
        components: {Pagination, ViewAction},
        mixins: [formatContentMixin, mediaGroupActionsMixin],
        data() {
            return {
                mediaGroupData: [],
                mediaGroupArchiveMediaData: [],
                mediaGroupArchiveMediaPagination: [],
                selectedMediaItems: [],
            }
        },
        computed: {
            ...mapState(['pageTitle']), // imports from store
            mediaGroupID() {
                return this.$route.params.mediaGroupID;
            },
            text() {
                return lang;
            },
            pageTitlePrefix() {
                return this.mediaGroupData.archived ? " (" + this.text.archived + ")" : "";
            },
            mediaBasePath() {
                return MEDIA_ROOT;
            }
        },
        methods: {
            ...mapActions(["setPageTitleAction", "setBreadCrumbsAction"]),
            async refreshMediaItemList(page = 1){
                this.selectedMediaItems  = [];
                await this.loadMediaGroupArchiveMediaByID(this.mediaGroupID, page);
            },
            async getMediaGroupData(){
                this.mediaGroupData = await this.loadMediaGroupByID(this.mediaGroupID);

                // update page title
                this.setPageTitleAction({ title : this.pageTitle.title.replace("%s%", this.mediaGroupData.name + this.pageTitlePrefix) });

                // update breadcrumbs
                this.setBreadCrumbsAction({
                    route : this.$route.matched,
                    replace : [{
                        search : "%mediaGroupName%",
                        replace : this.mediaGroupData.name
                    }]
                });
            },
            restoreSelected(){
                this.restoreMediaItems(this.selectedMediaItems, 'refreshMediaItemList');
            }
        },
        async created() {
            this.getMediaGroupData();
        },
        async mounted() {
            this.refreshMediaItemList(1);
        }
    }
</script>