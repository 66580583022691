<template>
    <div>
        <div class="table-scroller table-shadow">
            <table>
                <thead>
                <tr>
                    <th class="w-10/12">{{text.media_group_name}}</th>
                    <th class="w-2/12">{{text.actions}}</th>
                </tr>
                </thead>
                <tbody v-if="activeTableData.length > 0">
                <tr v-for="row in activeTableData" :key="row.id">
                    <td><router-link :to="{ name: 'AdminMediaGroupMedia', params: { 'mediaGroupID' : row.id } }" :title="text.edit + ' ' + row.name" class="default-action">{{row.name}}</router-link></td>
                    <td>
                        <ViewAction :routeName="'AdminMediaGroupMedia'" :routeParams="{ 'mediaGroupID' : row.id }" :linkName="row.name" />
                        <ArchiveAction :linkName="row.name" @actionCalled="archiveMediaGroup(row.id, 'refreshMediaGroupList')" />
                    </td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                    <td colspan="2">{{text.no_results}}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import lang from "@/shared/lang";
    import {MESSAGE_TYPE_SUCCESS} from "@/shared/consts";

    import {formatContentMixin} from "@/views/mixins/formatContent";
    import {mediaGroupActionsMixin} from "@/views/mixins/mediaGroupActions";

    import ViewAction from "@/components/actionlinks/view";
    import ArchiveAction from "@/components/actionlinks/archive";

    export default {
        name: 'MediaGroupList',
        components: {ViewAction, ArchiveAction},
        mixins: [formatContentMixin,mediaGroupActionsMixin],
        data() {
            return {
                activeTableData: [],
                activeTablePagination: [],
            }
        },
        computed: {
            text() {
                return lang;
            },
        },
        methods: {
            async refreshMediaGroupList(){
                await this.loadMediaGroupList();
            },
            showMessage() {
                if(this.message === "saved"){
                    this.$store.dispatch('addSystemMessageAction', {
                        'type' : MESSAGE_TYPE_SUCCESS,
                        'message' : lang.message_media_group_edit_saved
                    });
                }
                else if(this.message === "added"){
                    this.$store.dispatch('addSystemMessageAction', {
                        'type' : MESSAGE_TYPE_SUCCESS,
                        'message' : lang.message_media_group_added
                    });
                }

            },
        },
        async mounted() {
            this.refreshMediaGroupList();
            this.showMessage();
        },
    }
</script>
