<template>
    <div >
        <div class="table-scroller">
            <table class="archive">
                <thead>
                <tr>
                    <th class="w-10/12">{{text.media_group_name}}</th>
                    <th class="w-2/12">{{text.actions}}</th>
                </tr>
                </thead>
                <tbody v-if="archiveTableData.length > 0">
                <tr v-for="row in archiveTableData" :key="row.id">
                    <td><router-link :to="{ name: 'AdminMediaGroupArchiveMedia', params: { 'mediaGroupID' : row.id } }" :title="text.edit + ' ' + row.name" class="default-action">{{row.name}}</router-link></td>
                    <td>
                        <ViewAction :routeName="'AdminMediaGroupArchiveMedia'" :routeParams="{ 'mediaGroupID' : row.id }" :linkName="row.name" />
                        <RestoreAction :linkName="row.name" @actionCalled="restoreMediaGroup(row.id, 'refreshArchiveMediaGroupList')" />
                    </td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                    <td colspan="2">{{text.no_results}}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import lang from "@/shared/lang";

    import {formatContentMixin} from "@/views/mixins/formatContent";
    import {mediaGroupActionsMixin} from "@/views/mixins/mediaGroupActions";

    import ViewAction from "@/components/actionlinks/view";
    import RestoreAction from "@/components/actionlinks/restore";

    export default {
        name: 'MediaGroupArchive',
        components: {ViewAction, RestoreAction},
        mixins: [formatContentMixin,mediaGroupActionsMixin],
        data() {
            return {
                archiveTableData: [],
                archiveTablePagination: [],
            }
        },
        computed: {
            text() {
                return lang;
            },
        },
        methods: {
            async refreshArchiveMediaGroupList(){
                await this.loadArchiveMediaGroupList();
            }
        },
        async mounted() {
            this.refreshArchiveMediaGroupList();
        },
    }
</script>