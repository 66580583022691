<template>
    <div class="columns" :class="{ archive : mediaItemData.archived}" v-if="mediaItemData.name">
        <div class="content">
            <div class="panel panel-body">
                <span class="image-border"><img v-auth-src="mediaBasePath + mediaItemData.previewFilePath" :alt="mediaItemData.name | cleanString" /></span>
            </div>
        </div>
        <div class="sidebar-right sidebar-right-full">
            <router-link :to="{ name: 'AdminMediaGroupMediaViewOriginal' }" :title="text.view_original_file" class="btn btn-tertiary btn-smyspace" target="_blank">{{text.view_original_file}}</router-link>
            <router-link :to="{ name: 'AdminMediaGroupMediaEdit' }" :title="text.edit" class="btn btn-secondary btn-smyspace">{{text.edit}}</router-link>
            <a href="#" :title="text.archive_file" class="btn" @click="archiveThisMediaItem($event)" @keyup.enter="archiveThisMediaItem($event)" v-if="!mediaItemData.archived">{{text.archive_file}}</a>
            <a href="#" :title="text.restore_file" class="btn" @click="restoreThisMediaItem($event)" @keyup.enter="restoreThisMediaItem($event)" v-if="!mediaGroupData.archived && mediaItemData.archived">{{text.restore_file}}</a>
        </div>
    </div>
</template>

<script>
    import lang from "@/shared/lang";
    import {log} from "@/shared/utils";
    import {MEDIA_ROOT} from "@/shared/consts";
    import {mapActions, mapState} from "vuex";
    import {MESSAGE_TYPE_SUCCESS} from "@/shared/consts";

    import {formatContentMixin} from "@/views/mixins/formatContent";
    import {mediaGroupActionsMixin} from "@/views/mixins/mediaGroupActions";

    export default {
        name: 'MediaGroupMediaView',
        mixins: [formatContentMixin, mediaGroupActionsMixin],
        data() {
            return {
                mediaGroupData: [],
                mediaItemData: [],
            }
        },
        computed: {
            ...mapState(['pageTitle']), // imports from store
            mediaGroupID() {
                return this.$route.params.mediaGroupID;
            },
            mediaItemID() {
                return this.$route.params.mediaItemID;
            },
            text() {
                return lang;
            },
            pageTitlePrefix() {
                return this.mediaItemData.archived ? " (" + this.text.archived + ") " : "";
            },
            mediaBasePath() {
                return MEDIA_ROOT;
            },
            message() {
                return this.$route.params.message
            }
        },
        methods: {
            ...mapActions(["setPageTitleAction", "setBreadCrumbsAction"]),
            async refreshMediaItem(){
                await this.loadMediaGroupMediaItemByID(this.mediaItemID);

                this.updatePageTitle();
            },
            async getMediaGroupData(){
                this.mediaGroupData = await this.loadMediaGroupByID(this.mediaGroupID);

                // update breadcrumbs
                this.setBreadCrumbsAction({
                    route : this.$route.matched,
                    replace : [{
                        search : "%mediaGroupName%",
                        replace : this.mediaGroupData.name
                    }]
                });
            },
            updatePageTitle() {
                log(["What info do I have here?", this.$route]);

                // update page title
                this.setPageTitleAction({
                    title : this.$route.meta.pageTitle.replace("%s%", this.mediaItemData.name) + this.pageTitlePrefix,
                    isEditable: true,
                    editableTitle : this.mediaItemData.name,
                    changeSaved : false
                });
            },
            async saveNewMediaItemName() {
                let result = await this.saveMediaItem(this.mediaItemData.id, this.pageTitle.editableTitle);

                if(result.status === 200){
                    this.refreshMediaItem(1);
                }
                else
                {
                    log(["Save result no ok"]);
                }
            },
            restoreThisMediaItem(e) {
                e.preventDefault();
                this.restoreMediaItem(this.mediaItemData.id, "refreshMediaItem");
            },
            archiveThisMediaItem(e) {
                e.preventDefault();
                this.archiveMediaItem(this.mediaItemData.id, "refreshMediaItem");
            },
            showMessage() {
                if(this.message === "updated"){
                    this.$store.dispatch('addSystemMessageAction', {
                        'type' : MESSAGE_TYPE_SUCCESS,
                        'message' : lang.message_image_edit_saved
                    });
                }
                else if(this.message === "added"){
                    this.$store.dispatch('addSystemMessageAction', {
                        'type' : MESSAGE_TYPE_SUCCESS,
                        'message' : lang.message_image_edit_as_new
                    });
                }
            },
        },
        async mounted() {
            this.getMediaGroupData();
            this.refreshMediaItem();
            this.showMessage();
        },
        watch: {
            'pageTitle.changeSaved' (){
                log(["SAVED Watched page title change", this.pageTitle.editableTitle, this.mediaItemData.name, this.pageTitle.changeSaved]);

                if(this.pageTitle.changeSaved && (this.pageTitle.editableTitle !== this.mediaItemData.name)){
                    log(["Title change has been saved and is different from original"]);
                    this.saveNewMediaItemName();
                }
            },
        },
    }
</script>