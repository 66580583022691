// define a mixin object
import {log} from "@/shared/utils";
import {getMediaGroupList, getMediaGroupArchiveList, archiveMediaGroupByID, restoreMediaGroupByID, saveMediaItemCropByID, createMediaItemFromCrop, getMediaGroupByID, saveMediaGroupByID, getMediaItemsByMediaGroupID, getArchiveMediaItemsByMediaGroupID, getMediaItemByID, saveMediaItemByID, archiveMediaItemByID, restoreMediaItemByID, archiveMediaItemsByIDs, restoreMediaItemsByIDs, getImageCreationOptions} from "@/shared/dataservice";
import {showDefaultServerErrorMessage} from "@/shared/utils";

export const mediaGroupActionsMixin = {
    methods: {
        async loadMediaGroupList() {
            let result = await getMediaGroupList();

            log(["Loaded media groups", true, result]);

            this.activeTableData = result.data.data;
            this.activeTablePagination = result.data.meta;
        },
        async loadArchiveMediaGroupList() {
            let result = await getMediaGroupArchiveList();

            log(["Loaded archive media groups", result]);

            this.archiveTableData = result.data.data;
            this.archiveTablePagination = result.data.meta;
        },
        async loadMediaGroupByID(id) {
            let result = await getMediaGroupByID(id);

            log(["Load media groups by id", result]);

            return result.data.data;
        },
        async loadMediaGroupMediaByID(id, page = 1) {
            let result = await getMediaItemsByMediaGroupID(id, page);

            log(["Load media group media by id", result]);

            this.mediaGroupMediaData = result.data.data;
            this.mediaGroupMediaPagination = result.data.meta;
        },
        async loadMediaGroupArchiveMediaByID(id, page = 1) {
            let result = await getArchiveMediaItemsByMediaGroupID(id, page);

            log(["Load media group archive media by id", result]);

            this.mediaGroupArchiveMediaData = result.data.data;
            this.mediaGroupArchiveMediaPagination = result.data.meta;
        },
        async loadMediaGroupMediaItemByID(id) {
            let result = await getMediaItemByID(id);

            log(["Load media item by id", result]);

            this.mediaItemData = result.data.data;
        },
        async loadImageCreationOptions() {
            let result = await getImageCreationOptions();

            log(["Load media image", result]);

            this.imageCreationOptions = result.data.data;
        },

        /**
         * ACTIONS
         */
        archiveMediaGroup: async function (id, successMethodName) {
            let result = await archiveMediaGroupByID(id);

            log(["Archive MediaGroup", id, result]);

            if(result.status === 200){
                this[successMethodName]();
            }
            else
            {
                showDefaultServerErrorMessage("Archive Media Group");
            }
        },
        restoreMediaGroup: async function (id, successMethodName) {
            let result = await restoreMediaGroupByID(id);

            log(["Restore MediaGroup", id, result]);

            if(result.status === 200){
                this[successMethodName]();
            }
            else
            {
                showDefaultServerErrorMessage("Restore Media Group");
            }
        },
        archiveMediaItem: async function (id, successMethodName) {
            let result = await archiveMediaItemByID(id);

            log(["Archive Media Item", id, result]);

            if(result.status === 200){
                this[successMethodName]();
            }
            else
            {
                showDefaultServerErrorMessage("Archive Media Item");
            }
        },
        restoreMediaItem: async function (id, successMethodName) {
            let result = await restoreMediaItemByID(id);

            log(["Restore Media Item", id, result]);

            if(result.status === 200){
                this[successMethodName]();
            }
            else
            {
                showDefaultServerErrorMessage("Restore Media Item");
            }
        },
        archiveMediaItems: async function (ids, successMethodName) {
            let result = await archiveMediaItemsByIDs({ ids : ids});

            log(["Archive Media Items", ids, result]);

            if(result.status === 200){
                this[successMethodName]();
            }
            else
            {
                showDefaultServerErrorMessage("Archive Media Items");
            }
        },
        restoreMediaItems: async function (ids, successMethodName) {
            let result = await restoreMediaItemsByIDs({ ids : ids});

            log(["Restore Media Items", ids, result]);

            if(result.status === 200){
                this[successMethodName]();
            }
            else
            {
                showDefaultServerErrorMessage("Restore Media Items");
            }
        },
        saveMediaItem: async function (id, name) {
            return saveMediaItemByID(id, { id: id, name: name });
        },
        saveMediaGroup: async  function (id, name) {
            return saveMediaGroupByID(id, { id: id, name: name });
        },
        saveMediaItemCrop: async function (id, data) {
            return saveMediaItemCropByID(id, data);
        },
        createMediaItemFromCrop: async function (data) {
            return createMediaItemFromCrop(data);
        },

        /**
         * SELECT AND DESELECT
         */
        selectAll: function(e){
            e.preventDefault();
            let imageSelects = document.getElementsByName('selectimage');
            imageSelects.forEach((imageSelect) => {
                this.selectedMediaItems.push(parseInt(imageSelect.value));
            });
            this.selectedMediaItems = [...new Set(this.selectedMediaItems)];
        },
        deselectAll: function(e){
            e.preventDefault();
            this.selectedMediaItems = [];
        }
    },
}